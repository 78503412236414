<template>
  <div class="content-box">
    <div class="content">
      <div class="search-action">
        <div class="inputs_filter">
          <el-input
            placeholder="请输入关键字"
            prefix-icon="el-icon-search"
            class="search-input"
            v-model="search"
            clearable
            @clear="getExpertList"
          >
          </el-input>
          <div class="search-btn" @click="getExpertList">搜索</div>
        </div>
      </div>

      <div class="recommend">
        <div class="recommend-title">
          <span class="big-title">为你推荐</span>
        </div>
        <div class="recommend-outer">
          <ul class="recommend-content">
            <li
              class="recommend-item"
              v-for="(item, idx) in zj"
              :key="idx"
              @click="to_expert(item.id)"
            >
              <div class="inner-type-box">
                <div class="top-img">
                  <img
                    src="../../../assets/img/huiyuanzhongxin/user.png"
                    alt=""
                  />
                </div>

                <div class="top-title" :title="item.name">
                  {{ item.name }}
                </div>

                <div class="type-name">
                  个人简历：
                  <span> {{ item.resume }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <!-- 分页 -->
        <div class="pagination">
          <pagination
            class="pages"
            v-show="total > 0"
            :total="total"
            layout="prev, pager, next"
            :page.sync="listQuery.page"
            :limit.sync="listQuery.limit"
            @pagination="getExpertList"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ExpertList } from "../../../common/js/api";
import Pagination from "@/common/Pagination";
export default {
  components: { Pagination },
  data() {
    return {
      zj: [],
      total: 0, //总条目数
      listQuery: {
        limit: 6,
        page: 1,
      },
      search: "",
    };
  },
  created() {
    this.search = this.$route.query.keyword;
    this.getExpertList();
  },
  methods: {
    // 获取专家库列表
    getExpertList() {
      ExpertList({
        resume: this.search,
        page: this.listQuery.page,
        limits: this.listQuery.limit,
      })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.zj = res.data.data;
            this.total = res.data.total;
          }
        })
        .catch(() => {});
    },
    // 前往专家详情
    to_expert(id) {
      let routeData = this.$router.resolve({
        path: "/expert",
        query: {
          id: id,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .search-action {
        margin: 20px 30% 16px 20%;
  }
  /deep/.el-input__inner {
    border: 0;
  }
  .recommend {
    .recommend-outer {
      .recommend-content {
        padding-bottom: 14px;
        padding-top: 14px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;

        .recommend-item:nth-child(3n + 0) {
          margin-right: 0;
        }
        .recommend-item {
          margin-top: 20px;
          width: calc(33.3% - 15px);
          height: 299px;
          margin-right: 20px;
          background: #fff;
          border-radius: 6px;
          box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
          cursor: pointer;
          font-size: 14px;
          padding: 28px 32px;
          box-sizing: border-box;
          .top-img {
            max-width: 129px;
            max-height: 129px;
            margin: 0 auto 10px;
          }
          .top-title {
            font-size: 18px;
            color: #00050a;
            text-align: center;
          }
          .type-name {
            margin-top: 25px;
            text-align: left;
            font-weight: 700;
            font-size: 14px;
            line-height: 1.5;
            letter-spacing: 0;
            overflow: hidden;
            display: -webkit-box;
            text-overflow: ellipsis;
            -webkit-line-clamp: 3; /*要显示的行数*/
            -webkit-box-orient: vertical;
            span {
              font-weight: normal;
              color: #696969;
            }
          }
        }
      }
    }
    .pagination {
      text-align: center;
    }
  }
}
</style>